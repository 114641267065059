import React, { Component } from "react";
import { GetSessionById } from "../services/SessionsRepository";

import moment from 'moment';
import { ClockIcon, MapIcon } from '@heroicons/react/outline'
import { SessionSpeakerInfo } from "./Session/SessionSpeakerInfo";
import { NotFoundSpeaker } from "./Session/NotFoundSpeaker";
import { RatingButton } from "./Home/RatingButton";

export class SessionPage extends Component {
    constructor(props) {
        super(props);
        this.loadData();
        this.setState({ sessionId: -1 });
    }


    id = this.props.match.params.id;
    session = {};

    loadData() {
        this.session = GetSessionById(this.id);
        this.setState({ sessionId: this.id });
    }

    componentDidUpdate() {
        this.loadData(this.id);
    }


    classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    render() {
        if (this.session == null) {
            return (
                <NotFoundSpeaker />
            )
        }
        return (
            <>
                <div className="h-full flex">
                    <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
                        <div className="flex-1 relative z-0 flex overflow-hidden">
                            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">

                                <article>
                                    <div>
                                        <div>
                                            <img className="h-32 w-full object-cover lg:h-48"
                                                src="https://devdaysa.blob.core.windows.net/blazorday/resources/176297fd6ef34ce6add6308efe21f402_promotion-image-2.png" alt="" />
                                        </div>
                                        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                                            <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                                                <div className="flex">
                                                    {this.session.speakers.map(speaker => (
                                                        <img
                                                            className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                                                            src={speaker.imageUrl}
                                                            alt={speaker.firstname + " " + speaker.lastname} />
                                                    ))}

                                                </div>
                                                <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                                                    <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                                                        <h1 className="text-2xl font-bold text-gray-900">
                                                            {this.session.title}
                                                        </h1>
                                                    </div>
                                                    <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                                                        <button
                                                            type="button"
                                                            className="inline-flex  px-4 py-2  shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                                        >
                                                            <MapIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                            <span>{this.session.room.title}</span>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="inline-flex  px-4 py-2  shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                                        >
                                                            <ClockIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                            <span>{moment(this.session.slot.startSlot).tz("Europe/Brussels").format("HH:mm")} - {moment(this.session.slot.endSlot).tz("Europe/Brussels").format("HH:mm")}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Description</dt>
                                                <dd
                                                    className="mt-1 max-w-prose text-sm text-gray-900 space-y-5"
                                                    dangerouslySetInnerHTML={{ __html: this.session.descriptions[0].description }}
                                                />
                                            </div>
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Langue</dt>
                                                <dd
                                                    className="mt-1 max-w-prose text-sm text-gray-900 space-y-5"
                                                    dangerouslySetInnerHTML={{ __html: this.session.lang }}
                                                />
                                            </div>
                                        </dl>
                                    </div>

                                    <div className="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
                                        <h2 className="text-sm font-medium text-gray-500">Orateurs</h2>
                                        <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
                                            {this.session.speakers.map((speaker) => (
                                                <SessionSpeakerInfo key={speaker.id} speaker={speaker} />
                                            ))}
                                        </div>
                                    </div>

                                    <RatingButton />
                                </article>
                            </main>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

