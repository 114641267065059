import { GlobeIcon } from "@heroicons/react/outline";
import React, { Component } from "react";

export class TeamMemberItem extends Component {
    person = {};

    render() {
        this.person = this.props.person;
        return (
            <li key={this.person.id} className="py-10 px-6 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left">
                <div className="space-y-6 xl:space-y-10">
                    <img className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                        src={this.person.pictureUrl} alt="" />
                    <div className="space-y-2 xl:flex xl:items-center xl:justify-between">
                        <div className="font-medium text-lg leading-6 space-y-1">
                            <h3 className="text-white">{this.person.firstname} {this.person.lastname}</h3>
                            <p className="text-indigo-400">{this.person.title}</p>
                        </div>

                        <ul role="list" className="flex justify-center space-x-5">
                            <li>
                                <a href={this.person.twitterUrl} rel="noreferrer" target="_blank" className="text-gray-400 hover:text-gray-300">
                                    <span className="sr-only">Twitter</span>
                                    <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                    </svg>
                                </a>
                            </li>
                            {() => {
                                if (this.person.companyUrl.length > 0) {
                                    return (
                                        <li>
                                            <a href={this.person.companyUrl} rel="noreferrer" target="_blank" className="text-gray-400 hover:text-gray-300">
                                                <span className="sr-only">
                                                    Site</span>
                                                <GlobeIcon className="w-5 h-5" />
                                            </a>
                                        </li>
                                    );
                                }
                            }}
                        </ul>
                    </div>
                </div>
            </li>
        );
    }
}
