export function GetSponsors() {
    return [
        {
            "name": "Oniryx",
            "logoUrl": "https://devdaysa.blob.core.windows.net/blazorday/images/new-sponsors/Logo%20Oniryx.png",
            "url": "https://www.oniryx.be/",
            "type": 10
        },
        {
            "name": "Busi",
            "logoUrl": "https://devdaysa.blob.core.windows.net/blazorday/images/new-sponsors/Logo%20BuSi.png",
            "url": "https://busi.eu/",
            "type": 20
        },
        {
            "name": "Corilus",
            "logoUrl": "https://devdaysa.blob.core.windows.net/blazorday/images/new-sponsors/Logo%20Corilus.png",
            "url": "https://www.corilus.be/fr/home",
            "type": 20
        },
        {
            "name": "Delaware",
            "logoUrl": "https://devdaysa.blob.core.windows.net/blazorday/images/new-sponsors/Logo%20Delaware.png",
            "url": "https://www.delaware.pro/fr-be",
            "type": 20
        },
        {
            "name": "Gaming1",
            "logoUrl": "https://devdaysa.blob.core.windows.net/blazorday/images/logo-square.png",
            "url": "https://www.gaming1.com/en/",
            "type": 20
        },
        {
            "name": "Mirahi",
            "logoUrl": "https://devdaysa.blob.core.windows.net/blazorday/images/Logo%20dark.png",
            "url": "https://mirahi.io/",
            "type": 20
        },
        {
            "name": "Proximus",
            "logoUrl": "https://devdaysa.blob.core.windows.net/blazorday/images/new-sponsors/Logo%20Proximus.png",
            "url": "https://proximusapi.enco.io ",
            "type": 20
        },
        {
            "name": "RingRing",
            "logoUrl": "https://devdaysa.blob.core.windows.net/blazorday/images/new-sponsors/Logo%20RingRing.jpg",
            "url": "https://www.ringring.be/",
            "type": 20
        },
        {
            "name": "Agence du Numérique",
            "logoUrl": "https://devdaysa.blob.core.windows.net/blazorday/images/AdN-Couleur-RVB-300x123.png",
            "url": "https://www.adn.be/fr/",
            "type": 60
        },
        {
            "name": "Arcana",
            "logoUrl": "https://www.devday.be/img/sponsors/arcana.png",
            "url": "http://www.arcanastudio.net/",
            "type": 70
        },
        {
            "name": "Birdit",
            "logoUrl": "https://www.devday.be/img/sponsors/birdit.png",
            "url": "http://www.birdit.be",
            "type": 70
        },
        {
            "name": "DevApps",
            "logoUrl": "https://www.devday.be/img/sponsors/devappsbe.png",
            "url": "http://www.devapps.be",
            "type": 70
        },
        {
            "name": "EDITx",
            "logoUrl": "https://devdaysa.blob.core.windows.net/blazorday/images/editx-logo-full-color%403x.png",
            "url": "https://www.editx.eu",
            "type": 70
        },
        {
            "name": "JetBrains",
            "logoUrl": "https://resources.jetbrains.com/storage/products/company/brand/logos/jb_beam.png",
            "url": "https://www.jetbrains.com/",
            "type": 70
        },
        {
            "name": "LinkedIn",
            "logoUrl": "https://www.devday.be/img/sponsors/linkedin.png",
            "url": "https://www.linkedin.com/",
            "type": 70
        },
        {
            "name": "MIC Belgique",
            "logoUrl": "https://www.devday.be/img/sponsors/mic.png",
            "url": "http://www.mic-belgique.be",
            "type": 70
        },
        {
            "name": "Sense of Tech",
            "logoUrl": "https://bedevday.blob.core.windows.net/public/sponsors/senseoftech.png",
            "url": "https://www.senseof.tech",
            "type": 70
        },
        {
            "name": "Sparkle",
            "logoUrl": "https://www.devday.be/img/sponsors/sparkle.png",
            "url": "http://www.sparkle.tech/",
            "type": 70
        },,
        {
            "name": "Uniwan",
            "logoUrl": "https://devdaysa.blob.core.windows.net/blazorday/images/sponsors/t%C3%A9l%C3%A9chargement%20(1).png",
            "url": "https://uniwan.be/",
            "type": 70
        },
        {
            "name": "Woogma",
            "logoUrl": "https://devdaysa.blob.core.windows.net/blazorday/images/sponsors/woogma.png",
            "url": "https://thelittlewozniak.com/",
            "type": 70
        }
    ]
};