import React, { Component } from 'react';
import { GetNextSessions } from '../../services/SessionsRepository';
import moment from 'moment';
import { momentTz } from 'moment-timezone';
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid'
import { CursorClickIcon, MailOpenIcon, UsersIcon } from '@heroicons/react/outline'

export class NextSessions extends Component {
    classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    constructor(props) {
        super(props);
        this.state = {
            currentDate: moment(),
        };

        this.loadData(moment());
    }

    loadData(momentArg) {
        this.sessions = GetNextSessions(momentArg);
        this.setState({
            currentDate: momentArg,
        });
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.setState({
                currentDate: moment()
            }),
            30 * 1000
        );
    }

    componentDidUpdate() {
        this.loadData(moment());
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    sessions = [];

    render() {
        if (this.sessions.length === 0)
            return null;
        return (
            <div class="pt-5">
                <h2 className="text-center text-gray-400 text-2xl font-semibold uppercase tracking-wide">What's next?</h2>
                {this.sessions.map(session => (
                    <div
                        key={session.id}
                        className="relative bg-white mx-4 my-4 pt-4 px-4 pb-16 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
                    >
                        <dt>
                            <p className="ml-2 text-2xl text-sm font-medium text-gray-500">{session.title}</p>
                            <p className="ml-2 text-sm font-medium text-gray-500 truncate">Présenté par
                                {session.speakers.map(speaker => (
                                    <span key={speaker.id} className="ml-2 text-sm font-medium text-gray-500">{speaker.firstname} {speaker.lastname}</span>
                                ))}
                            </p>
                        </dt>
                        <dd className="ml-2 pb-6 items-baseline">
                            <p className="text-gray-600 items-baseline text-sm font-semibold"> Heure :&nbsp;
                                <span className="font-semibold text-gray-900">{moment(session.slot.startSlot).tz("Europe/Brussels").format("HH:mm")}</span> -&nbsp;
                                <span className="font-semibold text-gray-900">{moment(session.slot.endSlot).tz("Europe/Brussels").format("HH:mm")}</span>
                            </p>
                            <p
                                className='text-gray-600 items-baseline text-sm font-semibold'
                            >
                                Room : &nbsp;<span className="font-semibold text-gray-900">{session.room.title}</span>

                            </p>
                            <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                                <div className="text-sm">
                                    <a href={"/session/" + session.id} className="font-medium text-indigo-600 hover:text-indigo-500">
                                        Afficher la session

                                    </a>
                                </div>
                            </div>
                        </dd>
                    </div>
                ))}
            </div>
        );
    }
}