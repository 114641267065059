import moment from 'moment';

const sessions = [
  {
    "textWhenOverlapping": null,
    "orderWhenLightTalkOnTheSameSlot": 0,
    "videoUrl": null,
    "room": {
      "id": 24,
      "isVisible": true,
      "title": ".NET - Salle 1",
      "liveLink": null,
      "chatId": null,
      "visibleFrom": "2021-11-03T00:00:00",
      "visibleTo": "2021-11-25T00:00:00",
      "sessions": null
    },
    "roomId": 24,
    "slot": {
      "id": 47,
      "title": "Keynote 2021",
      "startSlot": "2021-11-23T08:45:00",
      "endSlot": "2021-11-23T10:00:00",
      "hasOnlyOneSession": true
    },
    "slotId": 47,
    "lang": "EN",
    "isDisplayed": true,
    "isKeyNote": true,
    "imageUrl": null,
    "title": "The Effective Developer - Work Smarter, Not Harder",
    "level": "Level100",
    "id": 151,
    "descriptions": [
      {
        "id": 167,
        "title": "The Effective Developer - Work Smarter, Not Harder",
        "lang": "en",
        "description": "We’re agile, we’re doing DevOps, we work in cross-functional teams, and we use the latest developer pipeline tooling. With all those methodologies and technologies we should be highly effective, right? Probably not. Most of us still struggle with balancing coding speed and quality, working on the stuff that really makes a difference, and feeling constantly stressed by all the things we should learn.\r\n\r\nEffective developers don't just write clean, simple, and robust code. They also have a strong understanding of the entire development process and the problem that needs to be solved. They take time to learn, practice, and play.\r\n\r\nLearn how those developers build effective coding habits, think about the outcome first, reserve time for deep work, and much more. You’ll walk away from this talk with lots of ideas on how to work smarter, not harder.",
        "tags": "",
        "slug": "the-effective-developer---work-smarter-not-ha"
      }
    ],
    "speakers": [
      {
        "websiteUrl": null,
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": null,
        "instagramUrl": null,
        "linkedInUrl": null,
        "githubUrl": null,
        "id": 131,
        "firstname": "Sven",
        "title": "Developer Advocate, MongoDB",
        "lastname": "Peters",
        "imageUrl": "https://sessionize.com/image/8169-400o400o2-HqiBVAohVurPpqySTrbTjn.png",
        "company": null,
        "descriptions": [
          {
            "id": 156,
            "lang": null,
            "description": "Sven Peters, developer advocate at MongoDB, has been studying trends in software development for the last 10 years uncovering the cultural and technical attributes to help development teams work effectively and drive innovation. He has 20 years experience in writing code, leading teams, and sharing his experience with thousands of developers at uncountable conferences in 25+ countries.",
            "country": null
          }
        ]
      }
    ]
  },
  {
    "textWhenOverlapping": null,
    "orderWhenLightTalkOnTheSameSlot": 0,
    "videoUrl": null,
    "room": {
      "id": 24,
      "isVisible": true,
      "title": ".NET - Salle 1",
      "liveLink": null,
      "chatId": null,
      "visibleFrom": "2021-11-03T00:00:00",
      "visibleTo": "2021-11-25T00:00:00",
      "sessions": null
    },
    "roomId": 24,
    "slot": {
      "id": 48,
      "title": "Tranche 1A",
      "startSlot": "2021-11-23T10:00:00",
      "endSlot": "2021-11-23T11:00:00",
      "hasOnlyOneSession": false
    },
    "slotId": 48,
    "lang": "FR",
    "isDisplayed": true,
    "isKeyNote": false,
    "imageUrl": null,
    "title": "Dissection de async/await",
    "level": "Level100",
    "id": 145,
    "descriptions": [
      {
        "id": 161,
        "title": "Dissection de async/await",
        "lang": "en",
        "description": "L'utilisation de async/await en C# est maintenant très présente dans toutes les librairies ; si l’utiliser correctement est une chose, son fonctionnement interne est complexe. Comme beaucoup d’abstractions en informatique, en comprendre les rouages est fort instructif !",
        "tags": "",
        "slug": "dissection-de-asyncawait"
      }
    ],
    "speakers": [
      {
        "websiteUrl": "https://www.ingenico.com/",
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": "https://twitter.com/pvlerick",
        "instagramUrl": null,
        "linkedInUrl": "https://www.linkedin.com/in/pvlerick/",
        "githubUrl": "http://pvlerick.github.io/ ",
        "id": 2,
        "firstname": "Philippe",
        "title": "Architect at Worldline",
        "lastname": "Vlérick",
        "imageUrl": "https://sessionize.com/image/d81f-400o400o2-VX5sK159fVS9R3tHvrYgGg.jpg",
        "company": "Ingenico ePayments",
        "descriptions": [
          {
            "id": 4,
            "lang": "EN",
            "description": "10 ans d'expérience en développent, passionné de .NET",
            "country": "Belgium"
          },
          {
            "id": 29,
            "lang": "FR",
            "description": "10 ans d'expérience en informatique, je travaille aujourd'hui en tant qu'architecte pour Ingenico ePayments.",
            "country": "Belgique"
          }
        ]
      }
    ]
  },
  {
    "textWhenOverlapping": null,
    "orderWhenLightTalkOnTheSameSlot": 0,
    "videoUrl": null,
    "room": {
      "id": 25,
      "isVisible": true,
      "title": "Cloud - Salle 5",
      "liveLink": null,
      "chatId": null,
      "visibleFrom": "2021-11-01T00:00:00",
      "visibleTo": "2021-11-23T03:00:00",
      "sessions": null
    },
    "roomId": 25,
    "slot": {
      "id": 48,
      "title": "Tranche 1A",
      "startSlot": "2021-11-23T10:00:00",
      "endSlot": "2021-11-23T11:00:00",
      "hasOnlyOneSession": false
    },
    "slotId": 48,
    "lang": "FR",
    "isDisplayed": true,
    "isKeyNote": false,
    "imageUrl": null,
    "title": "OAuth Demystified",
    "level": "Level100",
    "id": 167,
    "descriptions": [
      {
        "id": 183,
        "title": "OAuth Demystified",
        "lang": "en",
        "description": "Are you lost when reading about OAuth implicit grants vs. code grants? Are you always struggling to understand the difference between Amazon Cognito user pools and Amazon Cognito federated identities? And how your corporate Active Directory fits into that picture? During this chalk talk, we demystify identity federation and whiteboard the main flows, allowing you to understand how to leverage these services to bring identity federation to your web or mobile applications.",
        "tags": "",
        "slug": "oauth-demystified"
      }
    ],
    "speakers": [
      {
        "websiteUrl": null,
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": null,
        "instagramUrl": null,
        "linkedInUrl": null,
        "githubUrl": null,
        "id": 135,
        "firstname": "Sebastien",
        "title": "Developer Advocate, AWS France",
        "lastname": "Stormacq",
        "imageUrl": "https://sessionize.com/image/294f-400o400o2-Qy4tgX1QKvpf9vyCWMRjQC.jpg",
        "company": null,
        "descriptions": [
          {
            "id": 162,
            "lang": null,
            "description": "As Technical Evangelist @ AWS, Seb is inspiring builders to unlock the value of the AWS cloud, using his secret blend of passion, enthusiasm, customer advocacy, curiosity and creativity.\r\n\r\nSébastien worked for the last 20+ years with large enterprises and startups alike to help them to transform their business requirements into technical solutions, to unlock the value of software, mobile applications and to scale systems to meet customer demand. Before joining the Evangelist team, he built & lead the AWS Technical Training team in EMEA, then he built & lead the Alexa Solution Architecture team in UK, France, Italy and Spain.",
            "country": null
          }
        ]
      }
    ]
  },
  {
    "textWhenOverlapping": null,
    "orderWhenLightTalkOnTheSameSlot": 0,
    "videoUrl": null,
    "room": {
      "id": 23,
      "isVisible": true,
      "title": "Various - Salle 6",
      "liveLink": null,
      "chatId": null,
      "visibleFrom": "2021-11-03T00:00:00",
      "visibleTo": "2021-11-25T00:00:00",
      "sessions": null
    },
    "roomId": 23,
    "slot": {
      "id": 49,
      "title": "Tranche 1B",
      "startSlot": "2021-11-23T10:30:00",
      "endSlot": "2021-11-23T11:30:00",
      "hasOnlyOneSession": false
    },
    "slotId": 49,
    "lang": "FR",
    "isDisplayed": true,
    "isKeyNote": false,
    "imageUrl": null,
    "title": "Pixel spell book",
    "level": "Level100",
    "id": 150,
    "descriptions": [
      {
        "id": 166,
        "title": "Pixel spell book",
        "lang": "en",
        "description": "Nous utilisons, dans nos applications, des images à longueur de temps mais force est de constater que leur fonctionnement est parfois proche de la magie noire. Dans cette session, nous entrerons au cœur des images, en expliquant comment les pixels fonctionnent et comment les manipuler avec ou sans framework dans un environnement cross-platform. \t\r\nAllohomora !",
        "tags": "",
        "slug": "pixel-spell-book"
      }
    ],
    "speakers": [
      {
        "websiteUrl": null,
        "googlePlusUrl": null,
        "facebookUrl": "https://www.facebook.com/samuel.blanchard.18",
        "twitterUrl": "https://twitter.com/samoteph",
        "instagramUrl": null,
        "linkedInUrl": "https://www.linkedin.com/in/samuel-blanchard-b912a3117/",
        "githubUrl": null,
        "id": 20,
        "firstname": "Samuel",
        "title": "Synergiz - Directeur de l'innovation",
        "lastname": "Blanchard",
        "imageUrl": "https://sessionize.com/image/30f9-400o400o2-WhYsdsgqvo2hr9fySuKiPf.png",
        "company": "Synergiz",
        "descriptions": [
          {
            "id": 22,
            "lang": "FR",
            "description": "Samuel Blanchard est directeur de l'innovation chez Synergiz.  il est passionné du Framework .NET et des technologies touchant à la Mixed Reality, à l'IA ainsi qu'aux UI 2D/3D.",
            "country": "France"
          }
        ]
      }
    ]
  },
  {
    "textWhenOverlapping": null,
    "orderWhenLightTalkOnTheSameSlot": 0,
    "videoUrl": null,
    "room": {
      "id": 22,
      "isVisible": true,
      "title": "Web - Salle 7",
      "liveLink": null,
      "chatId": null,
      "visibleFrom": "2021-11-03T20:00:00",
      "visibleTo": "2021-11-25T00:00:00",
      "sessions": null
    },
    "roomId": 22,
    "slot": {
      "id": 49,
      "title": "Tranche 1B",
      "startSlot": "2021-11-23T10:30:00",
      "endSlot": "2021-11-23T11:30:00",
      "hasOnlyOneSession": false
    },
    "slotId": 49,
    "lang": "FR",
    "isDisplayed": true,
    "isKeyNote": false,
    "imageUrl": null,
    "title": "On peut vraiment faire tout ça avec une Web App?!?",
    "level": "Level100",
    "id": 168,
    "descriptions": [
      {
        "id": 184,
        "title": "On peut vraiment faire tout ça avec une Web App?!?",
        "lang": "en",
        "description": "Certains développeurs sous-estiment souvent tout ce qu'il est désormais possible de faire avec une web app, que ce soit sous la forme d'une PWA (Progressive Web App) ou non. Dans cette session, nous verrons un panorama de différentes démos vous permettant de voyager. Certaines seront issues du travail que nous effectuons avec certains de nos partenaires stratégiques chez Windows APS: intégration dans l'OS, distribution dans les Stores, intégrer les capacités équivalentes à Teams dans votre app, faire de l'AR/VR et j'en passe! L'idée est que vous repartiez avec ce qu'il est possible de faire... et de ne pas faire pour mieux orienter vos choix futurs d'architecture. ",
        "tags": "",
        "slug": "on-peut-vraiment-faire-tout-ca-avec-une-web-a"
      }
    ],
    "speakers": [
      {
        "websiteUrl": null,
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": null,
        "instagramUrl": null,
        "linkedInUrl": null,
        "githubUrl": null,
        "id": 137,
        "firstname": "David",
        "title": "Senior Program Manager at Microsoft",
        "lastname": "Rousset",
        "imageUrl": "https://sessionize.com/image/e2c8-400o400o2-BrVBXhnXiA7iFsExEpzcg8.jpg",
        "company": null,
        "descriptions": [
          {
            "id": 160,
            "lang": null,
            "description": "David Rousset is a Senior Program Manager leading the PWABuilder OSS project. This project helps people to transform an existing web app to a complete PWA (Progressive Web App) ready to be published to the various App Stores. He’s also the co-author of Babylon.js, a WebGL 3D engine used by several Microsoft applications in Office as well as various external partners such as Adobe. As a former evangelist, he’s used to public in various conferences, mainly about web development but also about quantum computing as he's passionate about it. He’s based in Paris, France which will explain is weird but lovely accent.",
            "country": null
          }
        ]
      }
    ]
  },
  {
    "textWhenOverlapping": null,
    "orderWhenLightTalkOnTheSameSlot": 0,
    "videoUrl": null,
    "room": {
      "id": 25,
      "isVisible": true,
      "title": "Cloud - Salle 5",
      "liveLink": null,
      "chatId": null,
      "visibleFrom": "2021-11-01T00:00:00",
      "visibleTo": "2021-11-23T03:00:00",
      "sessions": null
    },
    "roomId": 25,
    "slot": {
      "id": 50,
      "title": "Tranche 2A",
      "startSlot": "2021-11-23T11:15:00",
      "endSlot": "2021-11-23T12:15:00",
      "hasOnlyOneSession": false
    },
    "slotId": 50,
    "lang": "FR",
    "isDisplayed": true,
    "isKeyNote": false,
    "imageUrl": null,
    "title": "MicroServices Architecture Overview",
    "level": "Level100",
    "id": 149,
    "descriptions": [
      {
        "id": 165,
        "title": "MicroServices Architecture Overview",
        "lang": "en",
        "description": "\r\nLes MicroServices font désormais partie de notre quotidien ; il est néanmoins important de comprendre d’où vient le besoin des MicroServices, et pourquoi les applications monolithiques tendent à disparaître.\r\nLes MicroServices apportent énormément d’avantages mais ils ont aussi des inconvénients ; nous devons en être conscients, car ils nous imposent un changement de « mindset » important sur notre manière d’aborder un problème.\r\n \r\nNous allons essayer, à travers cette session, de répondre à certaines questions telles que : \r\n \r\n•\tQuelle est l’architecture générale des MicroServices ?\r\n•\tDe quoi un MicroService est-il composé et pourquoi ?\r\n•\tQuels sont les différents moyens de faire communiquer nos microservices ? \r\n•\tQu’est-ce que docker/kubernetes et qu’apportent ils concrètement aux microservices ?\r\n•\tPourquoi et comment monitorer et tracer les informations ?\r\n \r\nL’agenda vous donnera une idée des points que nous allons tenter de couvrir : \r\n \r\n•\tPrésentation \r\n•\tPourquoi les MicroServices ?\r\n        o\tProblématique \r\n        o\tMonolithe vs MicroService\r\n        o\tChangement de MindSet\r\n•\tApi = MicroService ?\r\n•\tMicroService Architecture \r\n        o\tGlobal Overview \r\n        o\tCQRS + Mediator \r\n        o\tVersioning\r\n        o\tHealthCheck\r\n•\tDocker / Kubernetes \r\n       o\tOverview\r\n•\tCommunication entre MicroServices\r\n       o\tEventing\r\n       o\tgRPC\r\n       o\tHttpCall\r\n•\tGateway and ServiceMesh\r\n•\tAuthentication and Authorization\r\n•\tMonitoring / Tracing \r\n•\tContraintes\r\n•\tAvantages\r\n•\tRobots !\r\n",
        "tags": "",
        "slug": "microservices-architecture-overview"
      }
    ],
    "speakers": [
      {
        "websiteUrl": null,
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": null,
        "instagramUrl": null,
        "linkedInUrl": null,
        "githubUrl": null,
        "id": 121,
        "firstname": "Jonathan",
        "title": "The RingRing Company SA - Principal Solution Architect",
        "lastname": "Vanderoost",
        "imageUrl": "https://sessionize.com/image/d4e9-400o400o2-Top9JZKUpvyBPGPFHjewu2.jpg",
        "company": null,
        "descriptions": [
          {
            "id": 146,
            "lang": null,
            "description": "Je me prénomme Jonathan Vanderoost, je suis consultant .Net depuis 2006 et passionné de technologie depuis mon plus jeune âge, j’étais déjà un adepte du Commodor64, ça ne me rajeunit pas d’ailleurs ! \r\n\r\nJ'ai toujours aimer partager mes connaissances, c'est pour cela que je suis également professeur à l'EPHEC depuis de nombreuse années.\r\n\r\nJ’ai eu l’occasion de travailler sur la plateforme Microsoft du Framework 1.1 au 4.8.0, sans oublier le .NET core de 1.1 a la version 5. J’ai travaillé sur divers type de projets : Azure, Winforms, WCF, WPF, Silverlight, WWF, embarqué, Asp.Net « Classic », Asp.Net MVC (2,3,4), Mobile, Office365, Auto-Hosted Apps, Provider Apps, JQuery, JavaScript, HTML5, Docker, Kubernetes, Gateway, Payment, et j’en passe et même Sharepoint … \r\n\r\nCe qui me permet aujourd’hui d’avoir une connaissance tranversale du monde « Microsoft » et une base solide qui me permet d'apporter une expérience ou un retour métier lors de mes prises de décisions. Je me suis spécialisé dans l'architecture sur base de mes années de consultance mais je continue a me former et a développer afin de ne pas être déconnecté de la réalité du terrain.",
            "country": null
          }
        ]
      }
    ]
  },
  {
    "textWhenOverlapping": null,
    "orderWhenLightTalkOnTheSameSlot": 0,
    "videoUrl": null,
    "room": {
      "id": 24,
      "isVisible": true,
      "title": ".NET - Salle 1",
      "liveLink": null,
      "chatId": null,
      "visibleFrom": "2021-11-03T00:00:00",
      "visibleTo": "2021-11-25T00:00:00",
      "sessions": null
    },
    "roomId": 24,
    "slot": {
      "id": 50,
      "title": "Tranche 2A",
      "startSlot": "2021-11-23T11:15:00",
      "endSlot": "2021-11-23T12:15:00",
      "hasOnlyOneSession": false
    },
    "slotId": 50,
    "lang": "EN",
    "isDisplayed": true,
    "isKeyNote": false,
    "imageUrl": null,
    "title": "What is new in .NET 6 and the future of .NET",
    "level": "Level100",
    "id": 154,
    "descriptions": [
      {
        "id": 170,
        "title": "What is new in .NET 6 and the future of .NET",
        "lang": "en",
        "description": "November 2021 marked the release of .NET 6. In this session, I will talk about what is new in the latest version of .NET and what the future will bring for the .NET platform in general. What is the difference between .NET Framework, .NET Core and .NET 6? Expect a number of new features and examples of those features.",
        "tags": "",
        "slug": "what-is-new-in-net-6-and-the-future-of-net"
      }
    ],
    "speakers": [
      {
        "websiteUrl": null,
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": "https://twitter.com/djohnnieke",
        "instagramUrl": null,
        "linkedInUrl": "https://www.linkedin.com/in/johnnyhooyberghs",
        "githubUrl": "https://github.com/Djohnnie",
        "id": 98,
        "firstname": "Johnny",
        "title": "Passionate Developer",
        "lastname": "Hooyberghs",
        "imageUrl": "https://sessionize.com/image/ef28-400o400o2-88-ec9a-4a26-95a4-8ab436350067.e8acb853-0187-4c4d-a709-e2d4a6480a80.jpg",
        "company": "Involved ",
        "descriptions": [
          {
            "id": 120,
            "lang": "EN",
            "description": "Johnny Hooyberghs is a consultant for Involved since 2014 focusing on .NET architecture and backend development and a Microsoft MVP since 2020. Prior to joining Involved, he has had experience developing software for Corilus since 2008. He has been passionate about .NET ever since it was released and his areas of expertise are C#, .NET (Core), WCF, WinForms, WPF, ASP.NET (Core), Entity Framework (Core), Azure and ALM using the Microsoft Stack. Every now and then he enjoys doing some web development using JavaScript. Since 2010, Johnny spends some of his free time teaching .NET and C# for the adult education institute CVO Antwerpen. When he's not working or teaching, he likes some casual gaming, scuba diving, learning to play the piano, travelling the world and visiting as many theme parks as possible.",
            "country": "Belgium"
          }
        ]
      }
    ]
  },
  {
    "textWhenOverlapping": null,
    "orderWhenLightTalkOnTheSameSlot": 0,
    "videoUrl": null,
    "room": {
      "id": 22,
      "isVisible": true,
      "title": "Web - Salle 7",
      "liveLink": null,
      "chatId": null,
      "visibleFrom": "2021-11-03T20:00:00",
      "visibleTo": "2021-11-25T00:00:00",
      "sessions": null
    },
    "roomId": 22,
    "slot": {
      "id": 51,
      "title": "Tranche 2B",
      "startSlot": "2021-11-23T12:00:00",
      "endSlot": "2021-11-23T12:30:00",
      "hasOnlyOneSession": false
    },
    "slotId": 51,
    "lang": "FR",
    "isDisplayed": true,
    "isKeyNote": false,
    "imageUrl": null,
    "title": "Y a-t-il un copilote dans mon IDE ?",
    "level": "Level100",
    "id": 155,
    "descriptions": [
      {
        "id": 171,
        "title": "Y a-t-il un copilote dans mon IDE ?",
        "lang": "en",
        "description": "Cela fait des années qu'on nous dit que l'IA remplacera les développeurs… GitHub et Open AI tentent un premier pas dans le futur avec Copilot, une Intelligence Artificielle de pair programming dont la promesse est de te souffler tes lignes de codes avant même que tu y ais pensé. Intrigué ? Embarque à bord de VSCode et laisse toi guider par Copilot vers ta retraite anticipée (ou pas ;p).",
        "tags": "",
        "slug": "y-a-t-il-un-copilote-dans-mon-ide"
      }
    ],
    "speakers": [
      {
        "websiteUrl": "http://www.jems-group.com/",
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": "https://twitter.com/TiffanySouterre",
        "instagramUrl": null,
        "linkedInUrl": "https://www.linkedin.com/in/tiffanysouterre/",
        "githubUrl": "https://amagash.github.io/",
        "id": 110,
        "firstname": "Tiffany",
        "title": "DevRel @ Microsoft",
        "lastname": "Souterre",
        "imageUrl": "https://sessionize.com/image/4b29-400o400o2-VEBhCnJbm9SksH6jCcoJo7.jpg",
        "company": "Microsoft",
        "descriptions": [
          {
            "id": 133,
            "lang": "EN",
            "description": "I love science and I love data! After finishing a Ph.D. in genetic engineering, I continued my quest for discovering new patterns through data science and machine learning. I currently work as a Data Engineer and I play with machine learning algorithms on my free time. Someday, I wish to leverage artificial intelligence and genetics to improve people's life.",
            "country": "France"
          }
        ]
      }
    ]
  },
  {
    "textWhenOverlapping": null,
    "orderWhenLightTalkOnTheSameSlot": 0,
    "videoUrl": null,
    "room": {
      "id": 25,
      "isVisible": true,
      "title": "Cloud - Salle 5",
      "liveLink": null,
      "chatId": null,
      "visibleFrom": "2021-11-01T00:00:00",
      "visibleTo": "2021-11-23T03:00:00",
      "sessions": null
    },
    "roomId": 25,
    "slot": {
      "id": 52,
      "title": "Tranche 2C",
      "startSlot": "2021-11-23T12:30:00",
      "endSlot": "2021-11-23T13:00:00",
      "hasOnlyOneSession": false
    },
    "slotId": 52,
    "lang": "FR",
    "isDisplayed": true,
    "isKeyNote": false,
    "imageUrl": null,
    "title": "Be a Security Champion",
    "level": "Level100",
    "id": 142,
    "descriptions": [
      {
        "id": 158,
        "title": "Be a Security Champion",
        "lang": "en",
        "description": "Quand les développeurs et les opérations rencontrent la sécurité. \r\nLa démystification de la sécurité passe par l'encouragement d'un travail de collaboration entre les développeurs et les équipes de sécurité. Tout le monde peut devenir un spoc de la sécurité à l'intérieur de son équipe de développement dans son entreprise.\r\n",
        "tags": "",
        "slug": "be-a-security-champion"
      }
    ],
    "speakers": [
      {
        "websiteUrl": null,
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": null,
        "instagramUrl": null,
        "linkedInUrl": null,
        "githubUrl": null,
        "id": 119,
        "firstname": "Fernand",
        "title": "Approach, Senior Cyber Security Consultant",
        "lastname": "Vanseven",
        "imageUrl": "https://sessionize.com/image/3dbf-400o400o2-HVKuLsRTyZj1VXkWQbk1VR.jpeg",
        "company": null,
        "descriptions": [
          {
            "id": 144,
            "lang": null,
            "description": "Fernand est un Project Manager expérimenté de référence chez Approach pour l’implementation de DevSecOps chez nos clients.",
            "country": null
          }
        ]
      },
      {
        "websiteUrl": null,
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": null,
        "instagramUrl": null,
        "linkedInUrl": null,
        "githubUrl": null,
        "id": 142,
        "firstname": "Dimitri",
        "title": "Application Security Consultant at Approach",
        "lastname": "Vanheghe",
        "imageUrl": "https://sessionize.com/image/edcc-400o400o2-PJhSq7ByGHBmNttMBnLU6F.png",
        "company": null,
        "descriptions": [
          {
            "id": 167,
            "lang": null,
            "description": "20+ years of software development experience and 10+ years of application security experience. \r\nAdvocate of Secure SDLC, DevSecOps and how to improve security and compliance in Application development life cycle.",
            "country": null
          }
        ]
      }
    ]
  },
  {
    "textWhenOverlapping": null,
    "orderWhenLightTalkOnTheSameSlot": 0,
    "videoUrl": null,
    "room": {
      "id": 22,
      "isVisible": true,
      "title": "Web - Salle 7",
      "liveLink": null,
      "chatId": null,
      "visibleFrom": "2021-11-03T20:00:00",
      "visibleTo": "2021-11-25T00:00:00",
      "sessions": null
    },
    "roomId": 22,
    "slot": {
      "id": 53,
      "title": "Tranche 3",
      "startSlot": "2021-11-23T13:15:00",
      "endSlot": "2021-11-23T14:15:00",
      "hasOnlyOneSession": false
    },
    "slotId": 53,
    "lang": "FR",
    "isDisplayed": true,
    "isKeyNote": false,
    "imageUrl": null,
    "title": "Create multi-screen experiences for the new mobile web",
    "level": "Level100",
    "id": 159,
    "descriptions": [
      {
        "id": 175,
        "title": "Create multi-screen experiences for the new mobile web",
        "lang": "en",
        "description": "You've probably seen these new smartphones with foldable screens, like the Samsung Galaxy Fold, or with two screens like the Surface Duo. They're already on the market, so it's up to you to update and rethink your web apps for these devices! But how? Where to start? Fortunately, the web is constantly evolving and has already started adapting for these new devices, thanks to new APIs.\r\n\r\nIn this talk, we'll explore why and how leveraging multiple screens can help you create new user experiences for your Progressive Web Apps, by diving into new experimental web features. We'll also have a look at how we can adapt existing apps for these new devices while trying to avoid dealing with the quirkiness of these new CSS and JavaScript primitives.",
        "tags": "",
        "slug": "create-multi-screen-experiences-for-the-new-m"
      }
    ],
    "speakers": [
      {
        "websiteUrl": null,
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": null,
        "instagramUrl": null,
        "linkedInUrl": null,
        "githubUrl": null,
        "id": 133,
        "firstname": "Yohan",
        "title": "Fullstack developer & cloud advocate @ Microsoft",
        "lastname": "Lasorsa",
        "imageUrl": "https://sessionize.com/image/b1bf-400o400o2-QKKXp487ad3gtvA78b1dQv.png",
        "company": null,
        "descriptions": [
          {
            "id": 164,
            "lang": null,
            "description": "Open-source enthusiast and devoted software artisan, the web is the ultimate playground for Yohan. With a background of 10+ years in various fields such as applied research on mobile and IoT, architecture consulting and cloud applications development, he worked all the way down to the low-level stacks before diving into web development. As a full stack engineer and DIY hobbyist, he now enjoys pushing bits of JavaScript everywhere he can while sharing his passion with others.",
            "country": null
          }
        ]
      },
      {
        "websiteUrl": null,
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": null,
        "instagramUrl": null,
        "linkedInUrl": null,
        "githubUrl": null,
        "id": 136,
        "firstname": "Olivier",
        "title": "Developer Relations Program Manager at Microsoft & Google Developer Expert on Web Technologies",
        "lastname": "Leplus",
        "imageUrl": "https://sessionize.com/image/e8db-400o400o2-81a74894-0ace-4a4b-8651-8d7f9bc07b1d.jpg",
        "company": null,
        "descriptions": [
          {
            "id": 161,
            "lang": null,
            "description": "Developer Relation Program Manager at Microsoft and Google Developer Expert in Web Technologies. I love to share knowledge among developers and people in general.",
            "country": null
          }
        ]
      }
    ]
  },
  {
    "textWhenOverlapping": null,
    "orderWhenLightTalkOnTheSameSlot": 0,
    "videoUrl": null,
    "room": {
      "id": 23,
      "isVisible": true,
      "title": "Various - Salle 6",
      "liveLink": null,
      "chatId": null,
      "visibleFrom": "2021-11-03T00:00:00",
      "visibleTo": "2021-11-25T00:00:00",
      "sessions": null
    },
    "roomId": 23,
    "slot": {
      "id": 53,
      "title": "Tranche 3A",
      "startSlot": "2021-11-23T13:00:00",
      "endSlot": "2021-11-23T13:45:00",
      "hasOnlyOneSession": false
    },
    "slotId": 53,
    "lang": "FR",
    "isDisplayed": true,
    "isKeyNote": false,
    "imageUrl": null,
    "title": "[Oniryx Session] Ok Google: a llama story",
    "level": "Level100",
    "id": 178,
    "descriptions": [
      {
        "id": 194,
        "title": "[Oniryx Session] Ok Google: a llama story",
        "lang": "en",
        "description": "Découvrez comment exploiter la puissance de l’assistant de Google afin de simplifier des tâches du quotidien. Que ce soit pour vous raconter des blagues carambar ou automatiser des tâches au boulot, vous aurez les outils pour commencer dès demain.",
        "tags": "",
        "slug": "oniryx-session-ok-google-a-llama-story"
      }
    ],
    "speakers": [
      {
        "websiteUrl": null,
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": null,
        "instagramUrl": null,
        "linkedInUrl": null,
        "githubUrl": null,
        "id": 145,
        "firstname": "Loic",
        "title": "Solution Architect @ Onyrix",
        "lastname": "Magnette",
        "imageUrl": "https://sessionize.com/image/f260-400o400o2-DDV8NLQpPKaNPUGDHcxybT.jpg",
        "company": null,
        "descriptions": [
          {
            "id": 170,
            "lang": null,
            "description": "I have 10+ years of experience in the IT sector as consultant, software engineer. I am able to work collaboratively with team members and to adapt myself to various environments; I am eager to learn and to improve my expertise on a day-to-day basis.\r\n\r\nI've an expertise in functional and technical analysis, solution design, development. Furthermore, for a bit more than 3 years now, I’ve regularly taken the role of r team leader on various project. During those time, I'm involved in estimation of IT projects, resources staffing and reporting.\r\n\r\nI have a strong will to learn and improve myself continuously. I'm highly motivated, dynamic and sociable.",
            "country": null
          }
        ]
      },
      {
        "websiteUrl": null,
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": null,
        "instagramUrl": null,
        "linkedInUrl": null,
        "githubUrl": null,
        "id": 144,
        "firstname": "Damien",
        "title": ".Net Architect @ Oniryx",
        "lastname": "Vande Kerckhove",
        "imageUrl": "https://sessionize.com/image/821f-400o400o2-p8Ss115bbP4onHAENg6oJv.jpg",
        "company": null,
        "descriptions": [
          {
            "id": 169,
            "lang": null,
            "description": "SUCCESS IS AN ATTITUDE\r\n",
            "country": null
          }
        ]
      }
    ]
  },
  {
    "textWhenOverlapping": null,
    "orderWhenLightTalkOnTheSameSlot": 0,
    "videoUrl": null,
    "room": {
      "id": 24,
      "isVisible": true,
      "title": ".NET - Salle 1",
      "liveLink": null,
      "chatId": null,
      "visibleFrom": "2021-11-03T00:00:00",
      "visibleTo": "2021-11-25T00:00:00",
      "sessions": null
    },
    "roomId": 24,
    "slot": {
      "id": 54,
      "title": "Tranche 3B",
      "startSlot": "2021-11-23T13:45:00",
      "endSlot": "2021-11-23T14:45:00",
      "hasOnlyOneSession": false
    },
    "slotId": 54,
    "lang": "FR",
    "isDisplayed": true,
    "isKeyNote": false,
    "imageUrl": null,
    "title": "Visual Studio 2022 - Codez comme un Ninja",
    "level": "Level100",
    "id": 153,
    "descriptions": [
      {
        "id": 169,
        "title": "Visual Studio 2022 - Codez comme un Ninja",
        "lang": "en",
        "description": "Visual Studio aura bientôt 25 ans d’existence. Cet éditeur a accumulé un ensemble de fonctionnalités impressionnantes. Lors de cette session, vous apprendrez comment mieux l’utiliser : les extensions indispensables, trucs et astuces du débogage, points d'arrêt conditionnels, raccourcis clavier, refactorisation du code, Intellisense, live Unit Tests, Code Cleanup, …\r\n\r\nMais surtout, nous verrons les principales nouveautés de la version 2022.",
        "tags": "",
        "slug": "visual-studio-2022---codez-comme-un-ninja"
      }
    ],
    "speakers": [
      {
        "websiteUrl": "http://dvoituron.be/",
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": "https://twitter.com/denisvoituron",
        "instagramUrl": null,
        "linkedInUrl": "https://www.linkedin.com/in/denisvoituron/",
        "githubUrl": null,
        "id": 67,
        "firstname": "Denis",
        "title": "Oniryx - Senior .NET Tech Lead",
        "lastname": "Voituron",
        "imageUrl": "https://sessionize.com/image/727f-400o400o2-3wJG6FzM1i3bVuhmSfV5o3.jpg",
        "company": "Oniryx",
        "descriptions": [
          {
            "id": 83,
            "lang": "FR",
            "description": "Programmer, Speaker, Teacher & Podcaster \r\n\r\nFor  more than 20 years, I have been sharing my passion as a developer through sessions, training or podcasts. Since 2008, I have been the main NET architect for the projects I coordinate for my clients. My work consists in designing IT applications and technically and methodologically monitoring the developers. All our projects are based on Agile SCRUM methodologies. I am SCRUM Master and Microsoft MVP certified.\r\n\r\nFor several months, I have been the founder and co-facilitator of the podcast DevApps.be dedicated to news, developments and architectures of.NET technologies.",
            "country": "Belgium"
          }
        ]
      }
    ]
  },
  {
    "textWhenOverlapping": null,
    "orderWhenLightTalkOnTheSameSlot": 0,
    "videoUrl": null,
    "room": {
      "id": 25,
      "isVisible": true,
      "title": "Cloud - Salle 5",
      "liveLink": null,
      "chatId": null,
      "visibleFrom": "2021-11-01T00:00:00",
      "visibleTo": "2021-11-23T03:00:00",
      "sessions": null
    },
    "roomId": 25,
    "slot": {
      "id": 54,
      "title": "Tranche 3B",
      "startSlot": "2021-11-23T13:45:00",
      "endSlot": "2021-11-23T14:45:00",
      "hasOnlyOneSession": false
    },
    "slotId": 54,
    "lang": "EN",
    "isDisplayed": true,
    "isKeyNote": false,
    "imageUrl": null,
    "title": "Monitoring at scale: detecting & fixing ineffective code",
    "level": "Level100",
    "id": 166,
    "descriptions": [
      {
        "id": 182,
        "title": "Monitoring at scale: detecting & fixing ineffective code",
        "lang": "en",
        "description": "After a few months of hard work & extensive testing, we launched our new shiny feature. The first few hours after the launch were calm and nobody could have guessed what was about to happen. Shortly after the full rollout, pages started to stall, users experimented timeouts and system health dropped. At that point we decided to turn on the kill switch and spend the next couple of weeks investigating our code’s efficiency. In this talk I will cover what we did to find the bottleneck, analyze it and finally optimize our code to run 5 times faster.  \r\n",
        "tags": "",
        "slug": "monitoring-at-scale-detecting-fixing-ineffect"
      }
    ],
    "speakers": [
      {
        "websiteUrl": null,
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": null,
        "instagramUrl": null,
        "linkedInUrl": null,
        "githubUrl": null,
        "id": 138,
        "firstname": "Alon",
        "title": "Tech lead @ Dropbox",
        "lastname": "Kiriati",
        "imageUrl": "https://sessionize.com/image/8c7f-400o400o2-7S4DxA7XMtfJmibk7dxvDu.jpg",
        "company": null,
        "descriptions": [
          {
            "id": 159,
            "lang": null,
            "description": "A full stack developer for the last 10+ years. I started from the very low level of RT/Embedded and went all the way \"up\" to react/js. Enthusiastic about culture, tech, product and ping pong. I believe everything in the world can be expressed with emojis, and usually check that every textbox I encounter supports them 👻",
            "country": null
          }
        ]
      }
    ]
  },
  {
    "textWhenOverlapping": null,
    "orderWhenLightTalkOnTheSameSlot": 0,
    "videoUrl": null,
    "room": {
      "id": 23,
      "isVisible": true,
      "title": "Various - Salle 6",
      "liveLink": null,
      "chatId": null,
      "visibleFrom": "2021-11-03T00:00:00",
      "visibleTo": "2021-11-25T00:00:00",
      "sessions": null
    },
    "roomId": 23,
    "slot": {
      "id": 55,
      "title": "Tranche 4A",
      "startSlot": "2021-11-23T14:45:00",
      "endSlot": "2021-11-23T15:45:00",
      "hasOnlyOneSession": false
    },
    "slotId": 55,
    "lang": "FR",
    "isDisplayed": true,
    "isKeyNote": false,
    "imageUrl": null,
    "title": "Développement d'une 'enterprise custom app' d’envergure en .NET 5 et React",
    "level": "Level100",
    "id": 144,
    "descriptions": [
      {
        "id": 160,
        "title": "Développement d'une 'enterprise custom app' d’envergure en .NET 5 et React",
        "lang": "en",
        "description": "Présentation sous forme de talk de la genèse d'un projet entreprise d'envergure, cross-team et full custom avec tous ses challenges et son impact au sein d'un carrière de développeur.",
        "tags": "",
        "slug": "developpement-dune-enterprise-custom-app-denv"
      }
    ],
    "speakers": [
      {
        "websiteUrl": null,
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": null,
        "instagramUrl": null,
        "linkedInUrl": null,
        "githubUrl": null,
        "id": 124,
        "firstname": "Luc",
        "title": "senior consultant @ delaware",
        "lastname": "Lepère",
        "imageUrl": "https://sessionize.com/image/d5cf-400o400o2-NEbX5St3C7o1JbqCHi1zYz.jpg",
        "company": null,
        "descriptions": [
          {
            "id": 149,
            "lang": null,
            "description": "développement .NET, web CMS et custom",
            "country": null
          }
        ]
      },
      {
        "websiteUrl": null,
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": null,
        "instagramUrl": null,
        "linkedInUrl": null,
        "githubUrl": null,
        "id": 140,
        "firstname": "Dimitri",
        "title": "Consultant @ delaware",
        "lastname": "Bosteels",
        "imageUrl": "https://sessionize.com/image/7c63-400o400o2-7MXh3LecF7P67WXEXV6cLL.png",
        "company": null,
        "descriptions": [
          {
            "id": 165,
            "lang": null,
            "description": "développement .NET, Microsoft 365, SharePoint et custom",
            "country": null
          }
        ]
      }
    ]
  },
  {
    "textWhenOverlapping": null,
    "orderWhenLightTalkOnTheSameSlot": 0,
    "videoUrl": null,
    "room": {
      "id": 22,
      "isVisible": true,
      "title": "Web - Salle 7",
      "liveLink": null,
      "chatId": null,
      "visibleFrom": "2021-11-03T20:00:00",
      "visibleTo": "2021-11-25T00:00:00",
      "sessions": null
    },
    "roomId": 22,
    "slot": {
      "id": 55,
      "title": "Tranche 4A",
      "startSlot": "2021-11-23T14:45:00",
      "endSlot": "2021-11-23T15:45:00",
      "hasOnlyOneSession": false
    },
    "slotId": 55,
    "lang": "EN",
    "isDisplayed": true,
    "isKeyNote": false,
    "imageUrl": null,
    "title": "Web Re-Architecturing and Tuning in Next JS",
    "level": "Level100",
    "id": 173,
    "descriptions": [
      {
        "id": 189,
        "title": "Web Re-Architecturing and Tuning in Next JS",
        "lang": "en",
        "description": "In this talk, we would cover steps to building a solid Next JS architecture with emphasis around functional structure, CI, and Deployment depending on the offerings of your application. We would also cover NextJS default performance settings and how you can optimize depending on the type of app you are building.",
        "tags": "",
        "slug": "web-re-architecturing-and-tuning-in-next-js"
      }
    ],
    "speakers": [
      {
        "websiteUrl": null,
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": null,
        "instagramUrl": null,
        "linkedInUrl": null,
        "githubUrl": null,
        "id": 134,
        "firstname": "Shodipo",
        "title": "Dev. Relations Manager at Stack Overflow",
        "lastname": "Ayomide",
        "imageUrl": "https://sessionize.com/image/bca4-400o400o2-3XixhaLkyM54h49mqizxRa.jpg",
        "company": null,
        "descriptions": [
          {
            "id": 163,
            "lang": null,
            "description": "Shodipo Ayomide is a Dev. Relations Manager at Stack Overflow with 9 years of experience in Technology and a track record in web & mobile applications engineering, community management, and product design on a global scale.\r\n\r\nHe has given talks/workshops at developer conferences around the globe at React Atlanta, FutureSync Conference, VueJS Amsterdam, VueJS Toronto, APIDAYS Hong Kong, Frontend Love Conference Amsterdam, FOSSASIA among many, he is also one of the organizers of the Developer Circles Lagos from Facebook, unStack Africa, Open-Source Community Africa, and various other communities empowering Africa and the world. In addition, he is an Expert and Instructor at egghead.io\r\n\r\nAmong his latest topics we can find: Progressive Imaging & Handling: React + WebPack, Fast and Furious with VueJS & WebPack, Getting up to Speed With Deno, Automating Workflow Processes Using GitHub Actions, Design from the Realm of Open-Source, Technical Principles to Developer Experience and others.",
            "country": null
          }
        ]
      }
    ]
  },
  {
    "textWhenOverlapping": null,
    "orderWhenLightTalkOnTheSameSlot": 0,
    "videoUrl": null,
    "room": {
      "id": 25,
      "isVisible": true,
      "title": "Cloud - Salle 5",
      "liveLink": null,
      "chatId": null,
      "visibleFrom": "2021-11-01T00:00:00",
      "visibleTo": "2021-11-23T03:00:00",
      "sessions": null
    },
    "roomId": 25,
    "slot": {
      "id": 56,
      "title": "Tranche 4B",
      "startSlot": "2021-11-23T15:15:00",
      "endSlot": "2021-11-23T16:15:00",
      "hasOnlyOneSession": false
    },
    "slotId": 56,
    "lang": "FR",
    "isDisplayed": true,
    "isKeyNote": false,
    "imageUrl": null,
    "title": "Event-driven applications in Azure",
    "level": "Level100",
    "id": 146,
    "descriptions": [
      {
        "id": 162,
        "title": "Event-driven applications in Azure",
        "lang": "en",
        "description": "Using Azure, it is easier than ever to build event-driven web applications, for example using Azure Functions and the Azure SignalR service. Laurent Bugnion, a Cloud Advocate for Microsoft will show you how he implemented such a solution to solve a real-world problem. This presentation will dive into a production application called Timekeeper, that Microsoft uses to run some of its live TV shows such as the Hello World daily show. More information about Timekeeper at http://timekeeper.cloud ",
        "tags": "",
        "slug": "event-driven-applications-in-azure"
      }
    ],
    "speakers": [
      {
        "websiteUrl": "http://lbugnion.me/",
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": "http://twitter.com/lbugnion",
        "instagramUrl": null,
        "linkedInUrl": "http://linkedin.com/in/lbugnion",
        "githubUrl": null,
        "id": 50,
        "firstname": "Laurent",
        "title": "Microsoft Cloud Developer Advocate",
        "lastname": "Bugnion",
        "imageUrl": "https://sessionize.com/image/7aa0-400o400o2-4g3Wpua111jTomzbDSTc3G.jpg",
        "company": "Microsoft",
        "descriptions": [
          {
            "id": 64,
            "lang": "EN",
            "description": "I work as Senior Cloud Developer Advocate for Microsoft after almost 10 years spent working for IdentityMine and Valorem, two leading firms in Microsoft technologies. I am one of the foremost experts for XAML and C# based development. I code in Azure, Windows, WPF, Xamarin (iOS and Android), ASP.NET. On my free time, I write for technical publications such as MSDN Magazine, and my blog is on blog.galasoft.ch. I am a frequent speaker at conferences such as VSLive, TechSummit, NDC, Oredev and many other international events. In the past I spoke at Microsoft MIX, TechEd, TechDays and more. Prior to joining Microsoft, I was a Microsoft Most Valuable Professional for Windows development from 2007 to 2017, a Microsoft Regional Director from 2013 and a Xamarin Most Valuable Professional from 2015. I am also the author of the well-known open source framework MVVM Light for Windows, WPF, Xamarin, and of the popular Pluralsight reference course about MVVM Light.\r\n",
            "country": "Switzerland"
          }
        ]
      }
    ]
  },
  {
    "textWhenOverlapping": null,
    "orderWhenLightTalkOnTheSameSlot": 0,
    "videoUrl": null,
    "room": {
      "id": 24,
      "isVisible": true,
      "title": ".NET - Salle 1",
      "liveLink": null,
      "chatId": null,
      "visibleFrom": "2021-11-03T00:00:00",
      "visibleTo": "2021-11-25T00:00:00",
      "sessions": null
    },
    "roomId": 24,
    "slot": {
      "id": 56,
      "title": "Tranche 4B",
      "startSlot": "2021-11-23T15:15:00",
      "endSlot": "2021-11-23T16:15:00",
      "hasOnlyOneSession": false
    },
    "slotId": 56,
    "lang": "FR",
    "isDisplayed": true,
    "isKeyNote": false,
    "imageUrl": null,
    "title": "L'immutabilité c'est maintenant (C#)",
    "level": "Level100",
    "id": 148,
    "descriptions": [
      {
        "id": 164,
        "title": "L'immutabilité c'est maintenant (C#)",
        "lang": "en",
        "description": "Vous avez surement appris à programmer avec des variables. Ce principe structure même votre façon d'aborder un problème. Et pourtant il existe d'autres approches qui permettent de s'en passer. Le langage C# dans sa longue évolution de 20 ans a également évolué dans ce sens peut-être sans que vous vous en soyez aperçu. Je vous propose ici d'en faire un tour d'horizon.",
        "tags": "",
        "slug": "limmutabilite-cest-maintenant-c"
      }
    ],
    "speakers": [
      {
        "websiteUrl": null,
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": null,
        "instagramUrl": null,
        "linkedInUrl": null,
        "githubUrl": null,
        "id": 22,
        "firstname": "MITSURU",
        "title": "CTO Lexlogos",
        "lastname": "FURUTA",
        "imageUrl": "https://sessionize.com/image/619f-400o400o2-WSB8TV79oa9K9GaP4FTj4g.jpg",
        "company": "Lexlogos",
        "descriptions": [
          {
            "id": 23,
            "lang": "EN",
            "description": "Anciennement speaker technique pour Microsoft France, Mitsuru est désormais CTO de la startup Lexlogos, spécialisée dans le traitement innovant de documents juridiques",
            "country": "France"
          }
        ]
      }
    ]
  },
  {
    "textWhenOverlapping": null,
    "orderWhenLightTalkOnTheSameSlot": 0,
    "videoUrl": null,
    "room": {
      "id": 23,
      "isVisible": true,
      "title": "Various - Salle 6",
      "liveLink": null,
      "chatId": null,
      "visibleFrom": "2021-11-03T00:00:00",
      "visibleTo": "2021-11-25T00:00:00",
      "sessions": null
    },
    "roomId": 23,
    "slot": {
      "id": 57,
      "title": "Tranche 5A",
      "startSlot": "2021-11-23T16:15:00",
      "endSlot": "2021-11-23T17:15:00",
      "hasOnlyOneSession": false
    },
    "slotId": 57,
    "lang": "FR",
    "isDisplayed": true,
    "isKeyNote": false,
    "imageUrl": null,
    "title": "Under the hood of eco-design",
    "level": "Level100",
    "id": 152,
    "descriptions": [
      {
        "id": 168,
        "title": "Under the hood of eco-design",
        "lang": "en",
        "description": "L'IT a une empreinte environnementale importante, et il est facile de la réduire par plusieurs moyens. L'un de ces moyens est la conception responsable (ou éco-conception) des services numériques.\r\n\r\nL'éco-conception des services numériques ne se limite pas aux sites web : les applications backend et middleware offrent également de multiples opportunités pour réduire notre empreinte environnementale.\r\nNous partagerons quelques principes clés de l'éco-conception avant d'approfondir les détails pratiques de la mise en œuvre réalisée chez Aeonics : un retour d'expérience basé sur l'expérience vécue, des choix techniques vs. inclusion & accessibilité, l'éco-conception comme base de business model pour une entreprise.",
        "tags": "",
        "slug": "under-the-hood-of-eco-design"
      }
    ],
    "speakers": [
      {
        "websiteUrl": null,
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": null,
        "instagramUrl": null,
        "linkedInUrl": null,
        "githubUrl": null,
        "id": 87,
        "firstname": "Olivier",
        "title": "Belgian Institute for Sustainable IT asbl/vzw",
        "lastname": "Vergeynst",
        "imageUrl": "https://sessionize.com/image/15c0-400o400o2-ce-db63-4a10-b4b5-8a37e7811b1e.4ac1a0fb-f615-4f4f-a6d1-f393c2967d00.jpg",
        "company": "Green IT Belgium",
        "descriptions": [
          {
            "id": 106,
            "lang": "FR",
            "description": "L'impact environnemental de l'IT reste méconnu. A la maison, nous trions nos déchets, nous avons un compost, nous prenons plus les transports en commun et moins la voiture… Chaque petite action compte, alors pourquoi pas au travail ?\r\n\r\nJ'ai passé plus de 20 ans dans l'IT sans réaliser que nous pouvions faire beaucoup plus pour sauvegarder l'environnement, à travers nos jobs d’informaticiens, de managers et d’utilisateurs de l’IT. Depuis, je consacre toute mon énergie à partager les bonnes pratiques du Numérique Responsable avec le plus grand nombre de personnes possible.",
            "country": "Belgique"
          }
        ]
      },
      {
        "websiteUrl": null,
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": null,
        "instagramUrl": null,
        "linkedInUrl": null,
        "githubUrl": null,
        "id": 130,
        "firstname": "Simon",
        "title": "CEO @ Aeonics SRL",
        "lastname": "Uyttendaele",
        "imageUrl": "https://sessionize.com/image/c69c-400o400o2-SrWrcCx51CqhMU633k3rnn.JPG",
        "company": null,
        "descriptions": [
          {
            "id": 155,
            "lang": null,
            "description": "En parallèle de mon Master en IA, j'ai découvert les problématiques de gestion de données et de la puissance machine nécessaire à leur publication. Après avoir créé un outil dédié à la gestion des capteurs IoT, j'ai décidé d'intégrer l'aspect environemental et sociétal dans un nouveau produit.\r\n\r\nAeonics est né avec la composante Numérique Responsable comme mantra. Au-delà de machines plus performantes et mieux gérées, l'architecture logicielle joue un rôle primordial sur les besoins. Plus efficace, plus efficient, plus inclusif.",
            "country": null
          }
        ]
      }
    ]
  },
  {
    "textWhenOverlapping": null,
    "orderWhenLightTalkOnTheSameSlot": 0,
    "videoUrl": null,
    "room": {
      "id": 22,
      "isVisible": true,
      "title": "Web - Salle 7",
      "liveLink": null,
      "chatId": null,
      "visibleFrom": "2021-11-03T20:00:00",
      "visibleTo": "2021-11-25T00:00:00",
      "sessions": null
    },
    "roomId": 22,
    "slot": {
      "id": 57,
      "title": "Tranche 5A",
      "startSlot": "2021-11-23T16:15:00",
      "endSlot": "2021-11-23T17:15:00",
      "hasOnlyOneSession": false
    },
    "slotId": 57,
    "lang": "FR",
    "isDisplayed": true,
    "isKeyNote": false,
    "imageUrl": null,
    "title": "Tips pour combattre le syndrome de l'imposteur",
    "level": "Level100",
    "id": 177,
    "descriptions": [
      {
        "id": 193,
        "title": "Tips pour combattre le syndrome de l'imposteur",
        "lang": "en",
        "description": "Qui n’a pas un jour prononcer la phrase : J’ai l’impression d’être un imposteur ? Je ne me sens pas légitime de faire ceci ou de faire cela ?\r\n\r\nCertaines personnes sont persuadées qu’elles ne méritent pas leur succès, malgré les efforts qu’elles fournissent pour réussir. Elles s’autopersuadent souvent que leur réussite n’est pas liée à leur travail, leur accomplissement personnel, mais tout bonnement à la chance ou bien au travail des autres. De fait, elles vivent en permanence avec un sentiment de duperie et craignent sans cesse que quelqu’un ne les démasque d’un jour à un autre.\r\n\r\nDans ce talk nous ferons un retour sur ce qu'est le syndrome de l'imposteur, comment il se reflète au quotidien et nous verrons que ce n'est pas une fatalité, au contraire, qu'il existe des tips et astuces pour le combattre, se dépasser et s'améliorer.",
        "tags": "",
        "slug": "tips-pour-combattre-le-syndrome-de-limposteur"
      }
    ],
    "speakers": [
      {
        "websiteUrl": null,
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": null,
        "instagramUrl": null,
        "linkedInUrl": null,
        "githubUrl": null,
        "id": 141,
        "firstname": "Aurélie",
        "title": "DevRel chez OVHcloud",
        "lastname": "Vache",
        "imageUrl": "https://sessionize.com/image/48dd-400o400o2-4vCqjaqJ6YgE6V9mkpAZ5u.jpg",
        "company": null,
        "descriptions": [
          {
            "id": 166,
            "lang": null,
            "description": "Aurélie is a DevRel (Developer Advocate) at OVHcloud in Toulouse, France. She is GDE (Google Developer Expert) for Cloud technologies, Docker captain, CNCF ambassador & Kubernetes for Developers certified. She has been working as a Developer and Ops for over 15 years. She built, deployed & operatized a lot of applications in different architectures and environments. She is a Cloud enthusiast and advocates DevOps/Cloud/Golang best practices. \r\n\r\nAs one of the leaders of Duchess France, an association that promotes women developers and women working in IT, she is heavily involved in their #AdoptADuchess coaching initiative, which helps beginner and retraining developers. \r\n\r\nShe has been a conferences and meetups organizer since 2016. She is also a technical writer (dev.to/aurelievache), a book author & reviewer, a sketchnoter and a speaker at international conferences: Devoxx France, Voxxed Days Luxembourg, DevFest Nantes…\r\n\r\nShe created a new visual way for people to learn and understand Cloud technologies: \"Understanding Kubernetes/Istio/Docker in a visual way\" in sketchnotes and videos.\r\n\r\nBlog: https://dev.to/aurelievache/\r\nYouTube: https://www.youtube.com/c/AurelieVache",
            "country": null
          }
        ]
      }
    ]
  },
  {
    "textWhenOverlapping": null,
    "orderWhenLightTalkOnTheSameSlot": 0,
    "videoUrl": null,
    "room": {
      "id": 25,
      "isVisible": true,
      "title": "Cloud - Salle 5",
      "liveLink": null,
      "chatId": null,
      "visibleFrom": "2021-11-01T00:00:00",
      "visibleTo": "2021-11-23T03:00:00",
      "sessions": null
    },
    "roomId": 25,
    "slot": {
      "id": 58,
      "title": "Tranche 5B",
      "startSlot": "2021-11-23T16:45:00",
      "endSlot": "2021-11-23T17:45:00",
      "hasOnlyOneSession": false
    },
    "slotId": 58,
    "lang": "FR",
    "isDisplayed": true,
    "isKeyNote": false,
    "imageUrl": null,
    "title": "Comment gérer une fuite de donnée comme un pro",
    "level": "Level100",
    "id": 143,
    "descriptions": [
      {
        "id": 159,
        "title": "Comment gérer une fuite de donnée comme un pro",
        "lang": "en",
        "description": "Vous avez subit une attaque, des données personnelles ont fuité, comment allez-vous réagir ? Atelier interactif, en petit groupes.\r\n\r\nAprès une petite introduction sur les règles en matière de notification de fuite de données personnelles, vous serez amenés à vous réunir en groupes de 4 à 5 personnes et à réfléchir, ensemble (de préférence), à ce que vous devez faire dans cette situation de crise.\r\n\r\nLe but de cet atelier est de vous préparer à la gestion d’une fuite de données, sans trop fâcher l'APD.",
        "tags": "",
        "slug": "comment-gerer-une-fuite-de-donnee-comme-un-pr"
      }
    ],
    "speakers": [
      {
        "websiteUrl": "http://laurie-anne.bourdain.name/",
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": "https://twitter.com/LaurieAnneB",
        "instagramUrl": null,
        "linkedInUrl": "https://www.linkedin.com/in/laurieannebourdain/",
        "githubUrl": null,
        "id": 73,
        "firstname": "Laurie-Anne",
        "title": "Isabel Group - Risk & Privacy Officer",
        "lastname": "Bourdain",
        "imageUrl": "https://sessionize.com/image/8d0b-400o400o2-e6-410c-43b8-814c-ade3c853982d.e759557f-7728-4a49-9fcf-f873414dfa40.jpg",
        "company": "Isabel Group",
        "descriptions": [
          {
            "id": 88,
            "lang": "EN",
            "description": "Laurie-Anne Bourdain is the risk and privacy officer at the Isabel Group and ensure top risk management and data protection practices within the group. She previously was a consultant within EY where she lead the privacy practice for Belgium (non-banking sector) and Senior Privacy Manager at Sony Electronics; in both positions she helped companies implementing the requirements of GDPR: from identification of personal data to design of documentation and handling of data subjects requests. She also have a strong background in information security and is certified CISSP, CIPP/E, CIPM, FIP, CDPSE & with the CNIL' DPO competencies. Laurie-Anne graduated Magna cum laude from the University of Strathclyde (UK) in a Master of Sciences in Information Management.",
            "country": "Belgium"
          },
          {
            "id": 89,
            "lang": "FR",
            "description": "Laurie-Anne Bourdain est responsable des risques et de la protection de la vie privée au sein du groupe Isabel et assure la gestion des risques et la protection des données au sein du groupe. Auparavant, elle était consultante au sein de EY, où elle dirigeait la pratique de protection de la vie privée pour la Belgique (secteur non bancaire) et Senior Privacy Manager chez Sony Electronics ; à ces deux postes, elle a aidé des entreprises à mettre en œuvre les exigences de GDPR : de l'identification des données personnelles à la conception des documents et au traitement des demandes des personnes concernées. Elle possède également une solide expérience en sécurité de l'information et est certifiée CISSP, CIPP/E et CIPM. Laurie-Anne est diplômée avec grande distinction de l'Université de Strathclyde (Royaume-Uni) en Master of Sciences in Information Management.",
            "country": "Belgique"
          }
        ]
      }
    ]
  },
  {
    "textWhenOverlapping": null,
    "orderWhenLightTalkOnTheSameSlot": 0,
    "videoUrl": null,
    "room": {
      "id": 24,
      "isVisible": true,
      "title": ".NET - Salle 1",
      "liveLink": null,
      "chatId": null,
      "visibleFrom": "2021-11-03T00:00:00",
      "visibleTo": "2021-11-25T00:00:00",
      "sessions": null
    },
    "roomId": 24,
    "slot": {
      "id": 58,
      "title": "Tranche 5B",
      "startSlot": "2021-11-23T16:45:00",
      "endSlot": "2021-11-23T17:45:00",
      "hasOnlyOneSession": false
    },
    "slotId": 58,
    "lang": "FR",
    "isDisplayed": true,
    "isKeyNote": false,
    "imageUrl": null,
    "title": "Introduction a AppCenter pour la distribution et le tests de vos applications mobiles",
    "level": "Level100",
    "id": 147,
    "descriptions": [
      {
        "id": 163,
        "title": "Introduction a AppCenter pour la distribution et le tests de vos applications mobiles",
        "lang": "en",
        "description": "Si vous êtes amenés à développer des applications mobiles, que ce soit directement en natif, ou avec une solution comme Xamarin, React Native ou bien encore Ionic, vous devez vous poser la question de comment gérer votre Intégration continue, mais aussi vos canaux de distribution, l'automatisation des tests, le débug et la gestion des versions depuis le développement jusqu'au stores iOS et Android.\r\n\r\nDans cette session, nous verrons pas à pas comment l'AppCenter de Microsoft nous propose de mettre en place cela très simplement quelque soit la technologie choisie.\r\n\r\nNous répondrons aussi à la question de la place d'AppCenter en complément d'une autre solution telle qu'Azure DevOps que votre organisation utilise déjà pour tous les développements . Vous verrez que non seulement ces deux plateformes ne s'opposent pas mais se complètent à merveille.",
        "tags": "",
        "slug": "introduction-a-appcenter-pour-la-distribution"
      }
    ],
    "speakers": [
      {
        "websiteUrl": "https://www.versusmind.eu/",
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": "https://twitter.com/DePhiless",
        "instagramUrl": null,
        "linkedInUrl": "https://www.linkedin.com/in/philippedidiergeorges/",
        "githubUrl": null,
        "id": 46,
        "firstname": "Philippe",
        "title": "Microsoft MVP & Bretzel Lover @ MUG Strasbourg",
        "lastname": "Didiergeorges",
        "imageUrl": "https://sessionize.com/image/b16e-400o400o2-LSmetXZHqPBJKJQ3UMiwwm.jpg",
        "company": "Versusmind",
        "descriptions": [
          {
            "id": 62,
            "lang": "FR",
            "description": "MVP Development Technologies depuis 2016, et Co-Fondateur du MUG Strasbourg, Philippe adore les Bretzels, les hackathons et la bière.",
            "country": "France"
          }
        ]
      },
      {
        "websiteUrl": null,
        "googlePlusUrl": null,
        "facebookUrl": null,
        "twitterUrl": null,
        "instagramUrl": null,
        "linkedInUrl": null,
        "githubUrl": null,
        "id": 143,
        "firstname": "Ahmed",
        "title": "Senior Software Developer Team Lead ",
        "lastname": "Zahri",
        "imageUrl": "https://sessionize.com/image/c9a6-400o400o2-fRxB6edBPeVWRuvadw4Avu.jpg",
        "company": null,
        "descriptions": [
          {
            "id": 168,
            "lang": null,
            "description": "Développeur informatique enthousiaste pour les méthodes et les technologies, j'aime travailler en équipe et relever des challenges.\r\n",
            "country": null
          }
        ]
      }
    ]
  }
];

export function GetSessions() {
  return sessions;
}

export function GetSessionById(id) {
  var session = sessions.filter(s => s.id == id);
  if (session.length > 0)
    return session[0];
  else
    return null;
}

export function GetNextSessions(currentMoment){
  var nextSessions = sessions.filter(s => moment(s.slot.startSlot).tz("Europe/Brussels").diff(currentMoment) > 0).slice(0, 4);
  if(nextSessions.filter(s => s.isKeyNote).length > 0)
  {
    return nextSessions.filter(s => s.isKeyNote);
  }
  return nextSessions;
}