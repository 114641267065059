import React from "react";

export class SessionSpeakerInfo extends React.Component {
    constructor(props) {
        super(props);
        this.speaker = this.props.speaker;
    }

    speaker = {};

    render() {
        return (
            <div
                key={this.speaker}
                className="relative rounded-lg border border-gray-300 bg-white px-6 py-4 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500"
            >
                <div className="flex-shrink-0">
                    <img className="h-10 w-10 rounded-full" src={this.speaker.imageUrl}
                        alt={this.speaker.firstname + " " + this.speaker.lastname} />
                </div>
                <div className="flex-1 min-w-0">
                    <a href="#" className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" />
                        <p className="text-sm font-medium text-gray-900">{this.speaker.firstname} {this.speaker.lastname}</p>
                        <p className="text-sm text-gray-500 truncate">{this.speaker.title}</p>
                    </a>
                </div>
            </div>
        );
    }
}
