import React, { Component, memo, useEffect, useState } from 'react';
import { GetSessions } from '../services/SessionsRepository';
import moment from 'moment';
import { NextSessions } from './Agenda/NextSessions';
import { Title } from './Agenda/Title';
import { OutdatedSessions } from './Agenda/OutdatedSessions';

export class AgendaPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentDate: moment(),
        };

        this.handleClick = this.handleClick.bind(this);
        this.loadData(moment());
    }

    nextSessions = [];
    outdatedSessions = [];

    handleClick(e) {
        e.preventDefault();
        this.setState({
            currentDate: moment()
        });
        this.loadData(moment());
    };

    componentDidMount() {
        this.timerID = setInterval(
            () => this.setState({
                currentDate: moment()
            }),
            30 * 1000
        );
    }

    componentDidUpdate() {
        this.loadData(moment());
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    loadData(currentDate) {
        const sessions = GetSessions();
        this.nextSessions = sessions.filter(session => moment(session.slot.endSlot).diff(currentDate) > 0);
        this.outdatedSessions = sessions.filter(session => moment(session.slot.endSlot).diff(currentDate) <= 0);
    };

    render() {
        return (
            <div className="relative bg-white">
                <div className="relative max-w-7xl mx-auto px-4 py-4 sm:py-4 sm:px-4 lg:py-16">
                    <div className="max-w-7xl mx-auto lg:max-w-none lg:mr-0 lg:ml-auto lg:pl-10">
                        <Title onClockClick={this.handleClick} />
                        <NextSessions sessions={this.nextSessions} />
                        <OutdatedSessions sessions={this.outdatedSessions} />
                    </div>
                </div>
            </div>
        )
    }
}

export default AgendaPage;