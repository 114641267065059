import React, { Component } from 'react';
import { NewspaperIcon, PhoneIcon, SupportIcon } from '@heroicons/react/outline'
import { GetSponsors } from '../services/SponsorsRepository';

export class SponsorsPage extends Component {

    sponsors = GetSponsors();

    render() {
        return (
            <div className="bg-white">
                <div className="relative pb-32 bg-gray-800">
                    <div className="absolute inset-0">
                        <img
                            className="w-full h-full object-cover"
                            src="https://www.devday.be/resource/promotion-image-2"
                            alt=""
                        />
                        <div className="absolute inset-0 bg-gray-800 mix-blend-multiply" aria-hidden="true" />
                    </div>
                    <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                        <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">Partenaires</h1>
                        <p className="mt-6 max-w-3xl text-xl text-gray-300">
                            Sponsors: <br /><br /> <i>Particulier ou entreprise qui finance une manifestation, une épreuve sportive, culturelle — ou un de ses participants. </i> <br />Wikipedia.<br /><br />
                            Pour le  <b>DevDay</b>, ce sont les partenaires qui sont de fidèles maillons de la communauté. <br />
                            Durant le  <b>DevDay</b>, la plupart seront présents dans l'espace Sponsors.<br />
                            N'hésitez pas à les rencontrer et à échanger avec eux.
                        </p>
                    </div>
                </div>

                <section
                    className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
                    aria-labelledby="contact-heading"
                >
                    <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
                        {this.sponsors.map((sponsor) => (
                            <div key={sponsor.name} className="flex flex-col bg-white rounded-2xl shadow-xl">
                                <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                                    <a href={sponsor.url} rel="noreferrer" target="_blank" >
                                        <img src={sponsor.logoUrl} alt={sponsor.name} />
                                    </a>
                                </div>
                                <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                                    <a href={sponsor.url} rel="noreferrer" target="_blank" className="text-base font-medium text-indigo-700 hover:text-indigo-600">
                                        Visitez le site de <span className="text-xl font-medium text-gray-900">{sponsor.name}</span>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            </div>
        )
    }


}