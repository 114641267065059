import React, { Component } from 'react';

export class ShowAgenda extends Component {
    render() {
        return (
            <div className="max-w-7xl mx-auto mb-6 -mt-8 px-4 sm:py-24 sm:px-6 lg:px-8">
                <div className="mt max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                    <div className="rounded-md shadow">
                        <a href="/agenda"
                            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                        >
                            Afficher l'agenda complet
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}