import React, { Component } from "react";
import { ChevronDownIcon } from '@heroicons/react/solid'

export class Footer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <footer className="bg-gray-800" aria-labelledby="footer-heading">
                <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-8 lg:px-8">
                    <div className="mt-4 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
                        <p className="mt-4 text-base text-gray-400 md:mt-0 md:order-1">
                            &copy; 2021 &nbsp;
                            <a href="https://www.senseof.tech" rel="noreferrer" target="_blank">Sense of Tech</a>
                            &nbsp; ❤️ &nbsp;
                            <a href="https://www.devday.be" rel="noreferrer" target="_blank">DevDay</a>. All rights reserved.
                        </p>
                    </div>
                </div>
            </footer>
        )
    }
}