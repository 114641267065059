export function GetTeams(){
    return [
        {
          "id": 1,
          "firstname": "Adrien",
          "lastname": "Clerbois",
          "title": "Founder @ Sense of Tech",
          "pictureUrl": "https://www.devday.be/img/teams/adrien.jpg",
          "mail": "adrien@devday.be",
          "companyUrl": "http://www.senseof.tech",
          "twitterUrl": "https://twitter.com/AClerbois"
        },
        {
          "id": 5,
          "firstname": "Christophe",
          "lastname": "Peugnet",
          "title": "Founder @ Sodeasoft",
          "pictureUrl": "https://www.devday.be/img/teams/christophe.jpg",
          "mail": "christophe@devday.be",
          "companyUrl": "NULL",
          "twitterUrl": "https://twitter.com/tossnet1"
        },
        {
          "id": 4,
          "firstname": "Denis",
          "lastname": "Voituron",
          "title": "Principal Software Architect @ Oniryx",
          "pictureUrl": "https://www.devday.be/img/teams/denis.jpg",
          "mail": "denis@devday.be",
          "companyUrl": "NULL",
          "twitterUrl": "https://twitter.com/denisvoituron"
        },
        {
          "id": 9,
          "firstname": "Flora",
          "lastname": "Dequenne",
          "title": "Agency Operations Manager @ Sparkle",
          "pictureUrl": "https://devdaysa.blob.core.windows.net/blazorday/teams/flora.jpg",
          "mail": "flora@devday.be",
          "companyUrl": "https://www.sparkle.tech/",
          "twitterUrl": "NULL"
        },
        {
          "id": 6,
          "firstname": "Frederic",
          "lastname": "Carbonelle",
          "title": "Crazy Guy @ le MIC",
          "pictureUrl": "https://www.devday.be/img/teams/frederic.jpg",
          "mail": "frederic@devday.be",
          "companyUrl": "NULL",
          "twitterUrl": "https://twitter.com/fcarbonnelle"
        },
        {
          "id": 2,
          "firstname": "Matthieu",
          "lastname": "Vandenhende",
          "title": "Founder @ Birdit",
          "pictureUrl": "https://www.devday.be/img/teams/matthieu.jpg",
          "mail": "matthieu@devday.be",
          "companyUrl": "https://www.birdit.be",
          "twitterUrl": "https://twitter.com/matthieuvdh"
        },
        {
          "id": 99,
          "firstname": "Melanie",
          "lastname": "Lepropre",
          "title": "Event Organizer @ Sunset events",
          "pictureUrl": "https://media-exp1.licdn.com/dms/image/C4E03AQEwltYnNBI6WA/profile-displayphoto-shrink_400_400/0/1517624112641?e=1643241600&v=beta&t=RmfV45vDGt2ydV0iglR3S9wmoJ4InUXCcBi4RagUw4g",
          "mail": "melanie@sunsetevents.be",
          "companyUrl": "https://www.sunsetevents.be/",
          "twitterUrl": "https://www.sunsetevents.be/"
        },
        {
          "id": 10,
          "firstname": "Nathan",
          "lastname": "Pire",
          "title": "Freelance @ WoogMa",
          "pictureUrl": "https://devdaysa.blob.core.windows.net/blazorday/teams/nathan.jpg",
          "mail": "nathan@devday.be",
          "companyUrl": "NULL",
          "twitterUrl": "NULL"
        },
        {
          "id": 7,
          "firstname": "Olivier",
          "lastname": "Matis",
          "title": "Founder @ Arcana Studio",
          "pictureUrl": "https://www.devday.be/img/teams/olivier.jpg",
          "mail": "olivier@devday.be",
          "companyUrl": "http://www.guruumeditation.net/",
          "twitterUrl": "https://twitter.com/GuruuMeditation"
        },
        {
          "id": 3,
          "firstname": "Renaud",
          "lastname": "Dumont",
          "title": "Founder @ Sparkle",
          "pictureUrl": "https://www.devday.be/img/teams/renaud.jpg",
          "mail": "renaud@devday.be",
          "companyUrl": "https://www.sparkle.tech/",
          "twitterUrl": "https://twitter.com/DumontRenaud"
        }
      ]
}