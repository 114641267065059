import { MicrophoneIcon } from '@heroicons/react/outline';
import React, { Component } from 'react';

/* This example requires Tailwind CSS v2.0+ */
export class NotFoundSpeaker extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <a  href="/agenda"
                type="button"
                className="my-5 relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                <MicrophoneIcon className="mx-auto h-12 w-12 text-gray-400 text-red-500" />
                <span className="mt-2 block text-sm font-medium text-gray-900">No session found...</span>
               <span className="mt-2 block text-sm font-medium text-gray-900" >Retourner à l'agenda</span>
            </a>
        )
    }
}
