import React, { Component } from 'react';
import { AgendaItem } from './AgendaItem';

export class OutdatedSessions extends Component {
    render() {
        if (this.props.sessions.length > 0) {
            return (
                <>
                    <a name="outdated-sesssion"></a>
                    <h3 className="mt-3 text-2xl font-extrabold text-gray-600 sm:text-3xl">
                        Sessions passées
                    </h3>
                    <div className="lg:grid lg:grid-2">
                        {this.props.sessions.map(session => (
                            <AgendaItem key={session.id} isOutdated="true" session={session} />
                        ))}
                    </div>
                </>
            );
        }
        else
            return null;
    }
}
