import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';

import './custom.css'
import { SponsorsPage } from './components/SponsorsPage';
import  AgendaPage  from './components/AgendaPage';
import { SessionPage } from './components/SessionPage';
import { TeamPage } from './components/TeamPage';

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/sponsors' component={SponsorsPage} />
        <Route path='/agenda' component={AgendaPage} />
        <Route path='/team' component={TeamPage} />
        <Route path='/session' component={SessionPage}>
          <Route path='/session/:id' component={SessionPage} />
        </Route>
      </Layout>
    );
  }
}
