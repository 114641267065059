import React, { Component } from 'react';
import { Welcome } from './Home/Welcome';
import { ShowAgenda } from './Home/ShowAgenda';
import { SponsorsHomePage } from './Home/SponsorsHomePage';
import { RatingButton } from './Home/RatingButton';
import { NextSessions } from './Home/NextSessions';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div className="bg-gray-50">
        <Welcome />
        <NextSessions />
        <SponsorsHomePage />
        <ShowAgenda />
        <RatingButton />
      </div>
    );
  }
}
