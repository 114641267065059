import React, { Component } from 'react';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

export class AgendaItem extends Component {

    constructor(props) {
        super(props);
        momentTimezone.tz.setDefault('Europe/Brussels');
        this.state = {
            isOutdated: props.isOutdated ?? false,
            session: props.session,
            date: moment(props.session.slot.startSlot).format('HH:mm')
        }
    }

    render() {
        var session = this.state.session;
        return (
            <a href={"/session/" + session.id}>
                <div className="mt-6 md:mr-6 lg:mr-6 sm:mt-6 sm:space-y-0 sm:grid lg:max-w-4xl xl:max-w-none">
                    <div className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
                        <div className="p-6">
                            <h2 className="text-lg leading-6 font-medium text-gray-900">
                                {session.title}
                            </h2>
                            <p className="mt-2 text-xs">
                                Heure :&nbsp;
                                <span className="font-extrabold text-gray-900"> {this.state.date}</span>
                            </p>
                            <p className="mt-1 text-xs">
                                Salle :&nbsp;
                                <span className=" font-extrabold text-gray-900">{session.room.title}</span>
                            </p>
                        </div>
                        <div className="pt-6 pb-8 px-6">
                            <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">{() => {return session.speakers.length > 1 ? "Orateurs" : "Orateur"}}</h3>
                            <ul role="list" className="mt-6 space-y-4">
                                {session.speakers.map((speaker) => (
                                    <li key={speaker.id} className="flex space-x-3">
                                        <img className="flex-shrink-0 h-10 w-10 text-green-500" aria-hidden="true" src={speaker.imageUrl} />
                                        <p>
                                            <span className="text-sm text-gray-800">{speaker.firstname} {speaker.lastname} </span>
                                            <br />
                                            <span className="text-sm text-gray-500">{speaker.title}</span>
                                        </p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </a>
        )
    }
}