import React, { Component } from 'react';
import { GetSponsors } from '../../services/SponsorsRepository';

export class SponsorsHomePage extends Component {
    sponsors = GetSponsors();

    render() {
        return (
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 bg-white">
                <h2 className="text-center text-gray-400 text-sm font-semibold uppercase tracking-wide bg-white">
                    Sans nos partenaires, rien n'aurait été possible.
                </h2>
                <div className="mt-8 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
                    {this.sponsors.filter(d => d.type < 60).map((item) => {
                        if (item.type === 10) {
                            return (
                                <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
                                    <a href={item.url} rel="noreferrer" target="_blank">
                                        <img className="" src={item.logoUrl} alt={item.name} />
                                    </a>
                                </div>)
                        }
                        else {
                            return (
                                <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                                    <a href={item.url} rel="noreferrer" target="_blank">
                                        <img className="" src={item.logoUrl} alt={item.name} />
                                    </a>
                                </div>)
                        }
                    })}
                </div>
                <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                    <div className="rounded-md shadow">
                        <a href="/sponsors"
                            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                        >
                            Voir nos sponsors
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}