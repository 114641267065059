import React, { Component } from 'react';
import { ClockIcon } from '@heroicons/react/outline';

export class Title extends Component {
    render() {
        return (
            <div className="flex justify-start align-middle">
                <div className="flex items-center justify-center h-8 w-8 rounded-md bg-indigo-500 text-white">
                    <a href="#" onClick={this.props.onClockClick}>
                        <ClockIcon className="h-4 w-4" aria-hidden="true" />
                    </a>
                </div>
                <h2 className="ml-3 my-auto text-3xl font-extrabold text-gray-900 sm:text-4xl">
                    Agenda
                </h2>
            </div>
        );
    }
}
