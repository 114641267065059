import React, { Component } from "react";
import { GetTeams } from "../services/TeamRepository";
import { TeamMemberItem } from "./Team/TeamMemberItem";


export class TeamPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            team: [],
            isLoading: true
        };
    }

    componentDidMount() {
        this.getTeam();
    }

    getTeam() {
        this.setState({ team: GetTeams() });
    }

    render() {
        return (
            <>
                <div className="bg-gray-900">
                    <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
                        <div className="space-y-12">
                            <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
                                <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">Rencontrez notre équipe.</h2>
                                <p className="text-xl text-gray-300">
                                    Durant l'événement, nous restons à votre disposition pour toutes vos questions. Habillez de nos plus beaux polos, nous vous rencontrerons et répondrons à vos demandes.
                                </p>
                            </div>
                            <ul role="list" className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:grid-cols-3 lg:gap-8">
                                {this.state.team.map((person) => (
                                    <TeamMemberItem person={person} key={person.id} />
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        )
    }



}