import React, { Component } from 'react';
import { GetSponsors } from '../../services/SponsorsRepository';

export class Welcome extends Component {

    sponsors = GetSponsors();

    render() {
        return (
            <div className="relative overflow-hidden">
                <div className="relative pt-6 pb-16 sm:pb-24">
                    <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
                        <div className="text-center">
                            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                                <span className="block">DevDay 2021</span>
                                <span className="block text-indigo-600">Retrouvailles</span>
                            </h1>
                            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                                Bienvenu.e sur le compagnon du DevDay 2021. Vous trouverez sur cette page toutes les informations utiles pour vous aider à profiter de nos retrouvailles.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="relative">
                    <div className="absolute inset-0 flex flex-col" aria-hidden="true">
                        <div className="flex-1" />
                        <div className="flex-1 w-full bg-gray-800" />
                    </div>
                    <div className="">
                        <img
                            className="relative rounded-lg"
                            src="/firstpage.png"
                            alt="Welcome image"
                        />
                    </div>
                </div>
            </div>
        );
    }
}